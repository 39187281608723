/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Banner, BodyText} from '@servicetitan/design-system';
import {Link} from 'components';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    h2: "h2",
    p: "p",
    pre: "pre",
    code: "code",
    h3: "h3",
    hr: "hr"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "navigation",
    style: {
      position: "relative"
    }
  }, "Navigation", React.createElement(_components.a, {
    href: "#navigation",
    "aria-label": "navigation permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "main-menu",
    style: {
      position: "relative"
    }
  }, "Main menu", React.createElement(_components.a, {
    href: "#main-menu",
    "aria-label": "main menu permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Bottom Navigation bar holds main menu for an App in both iOS and Android. It is the top level of a hierarchy in most cases."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\nspan: 6\n---\n<>\n\t<Headline size=\"small\">Android</Headline>\n\t<img src=\"/images/mobile/Android-bottomNavigation.png\" className=\"w-100\" />\n</>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\nspan: 6\n---\n<>\n\t<Headline size=\"small\">iOS</Headline>\n\t<img src=\"/images/mobile/iOS-bottomNavigation.png\" className=\"w-100\" />\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "drill-in",
    style: {
      position: "relative"
    }
  }, "Drill-in", React.createElement(_components.a, {
    href: "#drill-in",
    "aria-label": "drill in permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Drill-in is a standard pattern in both iOS and Android with slightly different visual representation. This is used to navigation within the hierarchy. The example below shows moving from All Post menu to a detailed post item."), "\n", React.createElement(_components.h3, {
    id: "android",
    style: {
      position: "relative"
    }
  }, "Android", React.createElement(_components.a, {
    href: "#android",
    "aria-label": "android permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\nspan: 6\n---\n<>\n\t<img src=\"/images/mobile/Android-drillIn-1.png\" className=\"w-100\" />\n\t<BodyText size=\"xsmall\" subdued>This is top of the hierarchy.</BodyText>\n</>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\nspan: 6\n---\n<>\n\t<img src=\"/images/mobile/Android-drillIn-2.png\" className=\"w-100\" />\n\t<BodyText size=\"xsmall\" subdued>Use <Icon name=\"arrow_back\" /> to navigation up the hierarchy.</BodyText>\n</>\n")), "\n", React.createElement(_components.h3, {
    id: "ios",
    style: {
      position: "relative"
    }
  }, "iOS", React.createElement(_components.a, {
    href: "#ios",
    "aria-label": "ios permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\nspan: 6\n---\n<>\n\t<img src=\"/images/mobile/iOS-drillIn-1.png\" className=\"w-100\" />\n\t<BodyText size=\"xsmall\" subdued>This is top of the hierarcy.</BodyText>\n</>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\nspan: 6\n---\n<>\n\t<img src=\"/images/mobile/iOS-drillIn-2.png\" className=\"w-100\" />\n\t<BodyText size=\"xsmall\" subdued>Use <code>Back</code> to navigation up the hierarcy.</BodyText>\n</>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "pull-to-refresh",
    style: {
      position: "relative"
    }
  }, "Pull to refresh", React.createElement(_components.a, {
    href: "#pull-to-refresh",
    "aria-label": "pull to refresh permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Pull to refresh is a standard pattern in both iOS and Android. This should be applied to any page that has updatable data."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\nspan: 6\n---\n<>\n\t<Headline size=\"small\">Android</Headline>\n\t<img src=\"/images/mobile/Android-refresh.png\" className=\"w-100\" />\n</>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\nspan: 6\n---\n<>\n\t<Headline size=\"small\">iOS</Headline>\n\t<img src=\"/images/mobile/iOS-refresh.png\" className=\"w-100\" />\n</>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "more-action",
    style: {
      position: "relative"
    }
  }, "More Action", React.createElement(_components.a, {
    href: "#more-action",
    "aria-label": "more action permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "When dealing with ", React.createElement(_components.code, null, "more"), " action, iOS and Android use different methods."), "\n", React.createElement(_components.h3, {
    id: "android-1",
    style: {
      position: "relative"
    }
  }, "Android", React.createElement(_components.a, {
    href: "#android-1",
    "aria-label": "android 1 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Uses ", React.createElement(_components.a, {
    href: "https://material.io/components/menus"
  }, "Menus"), " to reveal more actions."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\nspan: 6\n---\n<img src=\"/images/mobile/Android-more-1.png\" className=\"w-100\" />\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\nspan: 6\n---\n<img src=\"/images/mobile/Android-more-2.png\" className=\"w-100\" />\n")), "\n", React.createElement(_components.h3, {
    id: "ios-1",
    style: {
      position: "relative"
    }
  }, "iOS", React.createElement(_components.a, {
    href: "#ios-1",
    "aria-label": "ios 1 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Uses ", React.createElement(_components.a, {
    href: "https://developer.apple.com/design/human-interface-guidelines/ios/views/action-sheets/"
  }, "Action Sheets"), " to reveal more actions."), "\n", React.createElement(Banner, null, "In iOS 14 and above, there is now", React.createElement(Link, {
    href: "https://developer.apple.com/design/human-interface-guidelines/ios/controls/pull-down-menus/"
  }, "Pull-down menu"), "that is similar to Android's Menu component. If Pull-down menu is used, you will need to make sure we have fallback for lower iOS versions."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\nspan: 6\n---\n<img src=\"/images/mobile/iOS-more-1.png\" className=\"w-100\" />\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\nspan: 6\n---\n<img src=\"/images/mobile/iOS-more-2.png\" className=\"w-100\" />\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "list-swipe---horizontal",
    style: {
      position: "relative"
    }
  }, "List Swipe - Horizontal", React.createElement(_components.a, {
    href: "#list-swipe---horizontal",
    "aria-label": "list swipe   horizontal permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Both iOS and Android supports multi-directional swipe."), "\n", React.createElement(_components.h3, {
    id: "android-2",
    style: {
      position: "relative"
    }
  }, "Android", React.createElement(_components.a, {
    href: "#android-2",
    "aria-label": "android 2 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\nspan: 6\n---\n<img src=\"/images/mobile/Android-swipe-2.png\" className=\"w-100\" />\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\nspan: 6\n---\n<img src=\"/images/mobile/Android-swipe-1.png\" className=\"w-100\" />\n")), "\n", React.createElement(_components.h3, {
    id: "ios-2",
    style: {
      position: "relative"
    }
  }, "iOS", React.createElement(_components.a, {
    href: "#ios-2",
    "aria-label": "ios 2 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Banner, {
    title: "iOS supports multiple actions on swipe but for consistency across our apps, we recommend using single action per direction."
  }), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\nspan: 6\n---\n<img src=\"/images/mobile/iOS-swipe-2.png\" className=\"w-100\" />\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\nspan: 6\n---\n<img src=\"/images/mobile/iOS-swipe-1.png\" className=\"w-100\" />\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "modal",
    style: {
      position: "relative"
    }
  }, "Modal", React.createElement(_components.a, {
    href: "#modal",
    "aria-label": "modal permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Modals are used when requesting for confirmation, create or update existing resource, or\niOS uses Alerts, Sheets, and Views for all modal experiences while Android uses different types of Dialog for all modal experiences."), "\n", React.createElement(_components.h2, {
    id: "alert",
    style: {
      position: "relative"
    }
  }, "Alert", React.createElement(_components.a, {
    href: "#alert",
    "aria-label": "alert permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\nspan: 6\n---\n<>\n\t<Headline size=\"small\">Android</Headline>\n\t<img src=\"/images/mobile/Android-alert.png\" className=\"w-100\" />\n\t<BodyText size=\"xsmall\" subdued>Dialog component</BodyText>\n</>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\nspan: 6\n---\n<>\n\t<Headline size=\"small\">iOS</Headline>\n\t<img src=\"/images/mobile/iOS-alert.png\" className=\"w-100\" />\n\t<BodyText size=\"xsmall\" subdued>Alert component</BodyText>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "simple-dialog",
    style: {
      position: "relative"
    }
  }, "Simple Dialog", React.createElement(_components.a, {
    href: "#simple-dialog",
    "aria-label": "simple dialog permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\nspan: 6\n---\n<>\n\t<Headline size=\"small\">Android</Headline>\n\t<img src=\"/images/mobile/Android-simpleDialog.png\" className=\"w-100\" />\n\t<BodyText size=\"xsmall\" subdued>Dialog component</BodyText>\n</>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\nspan: 6\n---\n<>\n\t<Headline size=\"small\">iOS</Headline>\n\t<img src=\"/images/mobile/iOS-simpleDialog.png\" className=\"w-100\" />\n\t<BodyText size=\"xsmall\" subdued>Action Sheet component</BodyText>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "fullscreen-dialog",
    style: {
      position: "relative"
    }
  }, "Fullscreen Dialog", React.createElement(_components.a, {
    href: "#fullscreen-dialog",
    "aria-label": "fullscreen dialog permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\nspan: 6\n---\n<>\n\t<Headline size=\"small\">Android</Headline>\n\t<img src=\"/images/mobile/Android-fullscreenDialog.png\" className=\"w-100\" />\n\t<BodyText size=\"xsmall\" subdued>Dialog component</BodyText>\n</>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\nspan: 6\n---\n<>\n\t<Headline size=\"small\">iOS</Headline>\n\t<img src=\"/images/mobile/iOS-fullscreenDialog.png\" className=\"w-100\" />\n\t<BodyText size=\"xsmall\" subdued>View component</BodyText>\n</>\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
